.logoAndLink{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.reultsLogo{
    width: 18px;
    margin-right: 9px;
}
.linkTitle{
    font-size: 20px;
    color: #1a0dab;
    margin-bottom: 5px;
}
.titleLink{
    text-decoration: none;
}
.snippet{
    font-size: 14px;
    color: #4d5156;
    margin-top: 5px;
}
.resultBox{
    width: 600px;
    margin-bottom: 30px;
}
.displayLink{
    line-height: 20px;
    margin: 0px;
    font-size: 12px;
    color: #4d5156;
    width: 100%;
}

@media (max-width: 950px) {
    /* CSS styles for screens with a maximum width of 600px */
    .resultBox{
        width: 80vw;
        margin-bottom: 30px;
    }
  }