.logo{
    width: 150px;
    margin-right: 20px;
}
.searchBarContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #CACACA;
    height: 12vh;
    padding-left: 5vw;
}
.searchBarInnerContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 68%;
    flex-wrap: wrap;
}
.resultsSearch-bar {
    border: 1px solid #CACACA;
    width: 580px;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-radius: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.resultsSearchInput{
    width: 85%;
    height: 95%;
    border: none;
    /* color: #CACACA; */
    font-size: 16px;
}
.resultsSearchInput:focus{
    outline: none;
}
.resultsSearchIcon{
    color: #CACACA;
    font-size: 16px;
}
.resultsSearchResults{
    background-color: white;
    border: none;
}
.resultsSearchResults:hover{
    cursor: pointer;
}
.displayResultsContainer{
    padding: 5vh 10vw;
}
.Pagination{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.paginationArrowButton{
    background-color: white;
    border: none;
    margin-right: 3px;
    font-size: 14px;
    margin-bottom: 80px;
    color: #CACACA;
}
.paginationArrowButton:hover{
    background-color: white;
    border: none;
    cursor: pointer;
}
.paginationButtonActive{
    color: #4d5156;
}
@media (max-width: 950px) {
    .searchBarInnerContainer{
        justify-content: center;
    }
    .searchBarContainer{
        justify-content: center;
        padding: 10px 0px;
    }
    .logo{
        margin-bottom: 9px;
    }
  }